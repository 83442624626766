import React from "react";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import { Section, Text, Button, MainTitle, Title } from "../components/Core";
import TeamMembers from "./../sections/team-members/TeamMembers"
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "../utils/helperFn";
import heroImg from "./../assets/image/png/hero-wayleaves.jpg";
import wayleaveAbout from "./../assets/image/png/wayleave-about.png";
import separator from "./../assets/image/png/separator.jpg";
import { navigate } from 'gatsby';

import wayleave1 from './../assets/image/png/wayleave-1.jpg';
import wayleave2 from './../assets/image/png/wayleave-2.jpg';
import wayleave3 from './../assets/image/png/wayleave-3.jpg';
import wayleave4 from './../assets/image/png/wayleave-4.jpg';

import wayleavesBackground from './../assets/image/jpeg/wayleaves-background.jpg';

import bottomMotive from './../assets/image/png/bottom-motive.png';

import caretUp from './../assets/image/png/caret-up.png';
import caretDown from './../assets/image/png/caret-down.png';

import styled from "styled-components";

const Wayleaves = () => {

    const AbsoluteTitle = styled(Title)`
        position: absolute;
        color: #fff;
        top: 120px;
        left: 15px;
        @media (min-width: 576px) { 
            top: 35%;
            left: calc((100vw - 525px) / 2);
        }
        @media (min-width: 768px) { 
            top: 35%;
            left: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            top: 35%;
            left: calc((100vw - 945px) / 2);
        }

        @media (min-width: 1200px) {
            top: 35%;
            left: calc((100vw - 1125px) / 2);
        }
    `;

    const PinkTitle = styled(Title)`
        color: #db118b;
        margin-top: 100px;
        text-align: left;
        padding-left: 15px;
        @media (max-width: 991px) {
            margin-top: 20px;
        }
    `;

    const NarrowWrapper = styled.div`
        width: 65%;
        padding: 0 15px 0 15px;
        p {
            color: #fff;
            text-align: left;
            margin-bottom: 10px;
        }
        @media (max-width: 1400px) {
            width: 100%;
        }
        @media (max-width: 991px) {
            margin-bottom: 50px;
        }
    `;

    const Box = styled.div`
        background-color: #fff;
        padding: 50px;
        border-radius: 50px;
        box-shadow: 1px 1px 6px 1px rgba(0,0,0,0.20);
        @media (max-width: 991px) {
            padding: 25px;
            .first-img {
                margin-bottom: 25px;
            }
            .second-img {
                margin-top: 25px;
            }
        }
    `;

    const StyledImage = styled.img`
        border-radius: 20px;
        width: 100%;
    `;

    const StyledSubtitle = styled.h2`
        color: #000;
        font-weight: 600;
        font-size: 2.5rem;
        line-height: 1;
        letter-spacing: -0.56px;
    `;

    const StyledSection = styled(Section)`
        background: url(${wayleavesBackground});
    `;

    const GradientSection = styled(Section)`
        position: relative;
        background: linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%);
        h2, p, a {
            color: #fff !important;
        }
        .container {
            position: relative;
            padding-bottom: 100px;
        }
    `;

    const AbsoluteImage = styled.img`
        position: absolute;
        bottom: -50%;
        left: -5%;
    `;

    const StyledAccordion = styled(Accordion)`
        .accordion-item {
            border: none;
            border-bottom: 1px solid #ce178a !important;
        }
        .accordion-button {
            
        }
        .accordion-header .accordion-button {
            text-transform: unset;
            font-size: 1.25rem;
            padding-left: 0;
            padding-right: 0;
            background-color: transparent;
            color: #000;
        }
        .accordion-body {
            padding: 0 0 20px 0;
        }
        .accordion-button::after {
            background-image: url(${caretDown});
        }
        .accordion-item:last-of-type,
        .accordion-item:last-of-type .accordion-button {
            border-radius: 0 !important;
        }
    `;

    return (
        <>
            <Seo page="wayleaves" />
            <div className="hero-wayleaves">
                <img src={heroImg} className="w-100 mt-10" />
                <AbsoluteTitle>Wayleaves</AbsoluteTitle>
            </div>
            <PageWrapper>
                <Row className="justify-content-center text-center dark-bg">
                    <Col lg="6">
                        <img src={wayleaveAbout} className="w-100 first-img"/>
                    </Col>
                    <Col lg="6">
                        <PinkTitle>What is a wayleave?</PinkTitle>
                        <NarrowWrapper>
                            <Text>
                                A wayleave is a contract between the
                                owner or occupier of a piece of land and
                                a third party giving the third party a right
                                of access to install and maintain utility
                                network assets.
                            </Text>
                            <Text>
                                By agreeing to a wayleave, it enables us,
                                as a network operator, to install our
                                infrastructure on privately-owned land.
                            </Text>
                        </NarrowWrapper>
                    </Col>
                </Row>
                <StyledSection>
                    <Container>
                        <Box>
                            <Row>
                                <Col lg="6">
                                    <StyledSubtitle>
                                        Tenants in rented<br />
                                        accommodation
                                    </StyledSubtitle>
                                    <img src={separator} />
                                    <Text className="w-80 mt-3" fontSize={2} lineHeight={1.75}>
                                        If you are a tenant living in rented accommodation,
                                        you'll need permission from your landlord or managing
                                        agent to access full fibre broadband. Once permission
                                        is gained, we can come and install a brand new
                                        broadband service for you!
                                    </Text>
                                    <Button
                                        style={{
                                            background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
                                            border: 'none',
                                            width: 'auto',
                                            marginTop: '50px'
                                        }}
                                        className="btn-red hvr-bounce-to-left"
                                        onClick={() => navigate('/wayleaves-for-tenants')}
                                    >
                                        More Info
                                    </Button>
                                </Col>
                                <Col lg="6">
                                    <StyledImage src={wayleave1} className="second-img"/>
                                </Col>
                            </Row>
                        </Box>

                        <Box className="mt-5">
                            <Row>
                                <Col lg="6">
                                    <StyledImage src={wayleave2} className="first-img"/>
                                </Col>
                                <Col lg="6">
                                    <StyledSubtitle>
                                        Wayleaves for landlords<br />
                                        & managing agents
                                    </StyledSubtitle>
                                    <img src={separator} />
                                    <Text className="w-80 mt-3" fontSize={2} lineHeight={1.75}>
                                        If you are a landlord, or a managing agent for rented
                                        properties, you will need to grant permission by signing
                                        a wayleave agreement with us in order for your tenants
                                        to receive full fibre broadband.
                                    </Text>
                                    <Button
                                        style={{
                                            background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
                                            border: 'none',
                                            width: 'auto',
                                            marginTop: '50px'
                                        }}
                                        className="btn-red hvr-bounce-to-left"
                                        onClick={() => navigate('/wayleaves-for-landlords')}
                                    >
                                        More Info
                                    </Button>
                                </Col>
                            </Row>
                        </Box>

                        <Box className="mt-5">
                            <Row>
                                <Col lg="6">
                                    <StyledSubtitle>
                                        Landowners and private<br />
                                        road residents
                                    </StyledSubtitle>
                                    <img src={separator} />
                                    <Text className="w-80 mt-3" fontSize={2} lineHeight={1.75}>
                                        As a landowner, or a resident on a private road,
                                        your co-operation is integral for a successful nationwide rollout.
                                        In order to bring full fibre broadband to yourself
                                        and your neighbours, we may need access to your land to carry out vital work.
                                    </Text>
                                    <Button
                                        style={{
                                            background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
                                            border: 'none',
                                            width: 'auto',
                                            marginTop: '50px'
                                        }}
                                        className="btn-red hvr-bounce-to-left"
                                        onClick={() => navigate('/private-roads')}
                                    >
                                        More Info
                                    </Button>
                                </Col>
                                <Col lg="6">
                                    <StyledImage src={wayleave3} className="second-img"/>
                                </Col>
                            </Row>
                        </Box>

                        <Box className="mt-5">
                            <Row>
                                <Col lg="6">
                                    <StyledImage src={wayleave4} className="first-img"/>
                                </Col>
                                <Col lg="6">
                                    <StyledSubtitle>
                                        Wayleaves for<br />
                                        business
                                    </StyledSubtitle>
                                    <img src={separator} />
                                    <Text className="w-80 mt-3" fontSize={2} lineHeight={1.75}>
                                        Wanting to boost your team's productivity but rent
                                        out your offices? Accessing full fibre broadband can
                                        truly revolutionise your business' workflow. Help us
                                        obtain permission from your building owner to
                                        unlock full fibre for you.
                                    </Text>
                                    <Button
                                        style={{
                                            background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
                                            border: 'none',
                                            width: 'auto',
                                            marginTop: '50px'
                                        }}
                                        className="btn-red hvr-bounce-to-left"
                                        onClick={() => navigate('/commercial-wayleaves')}
                                    >
                                        More Info
                                    </Button>
                                </Col>
                            </Row>
                        </Box>
                    </Container>
                </StyledSection>
                <Section>
                    <Container>
                        <Row>
                            <Title className="centered">Wayleave FAQ's</Title>
                            <Col sm={12} className="mt-4">
                                <StyledAccordion>
                                    <Accordion.Item eventKey="1" key="accordion-1">
                                        <Accordion.Header>What is a wayleave?</Accordion.Header>
                                        <Accordion.Body>
                                            <Text fontSize={2} lineHeight={1.75}>
                                                A wayleave is a written legal agreement between us and the land or property owner.
                                                It gives us permission to install, maintain or repair the network equipment that’s on private property.
                                            </Text>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="2" key="accordion-2">
                                        <Accordion.Header>How much does it cost to have fibre installed?</Accordion.Header>
                                        <Accordion.Body>
                                            <Text fontSize={2} lineHeight={1.75}>
                                                There is no cost to residents, businesses, land or property owners to have full fibre installed; Netomnia
                                                will pay for any reasonable expenses that a landlord incurs in getting full fibre installed to buildings.
                                            </Text>
                                            <Text className="mt-3" fontSize={2} lineHeight={1.75}>
                                                If residents or businesses choose to take up a service with our partner ISP, only then will they be
                                                charged their monthly subscription costs.
                                            </Text>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="3" key="accordion-3">
                                        <Accordion.Header>How long does a wayleave last?</Accordion.Header>
                                        <Accordion.Body>
                                            <Text fontSize={2} lineHeight={1.75}>
                                                Usually, a wayleave is an ongoing agreement without an end date, so it will continue to apply
                                                to the apparatus it covers, even if the owner of the land or property changes.
                                            </Text>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="4" key="accordion-4">
                                        <Accordion.Header>What if I don't want to sign the wayleave?</Accordion.Header>
                                        <Accordion.Body>
                                            <Text fontSize={2} lineHeight={1.75}>
                                                Please speak to us regarding your concerns. We have a team that is dedicated
                                                to ensuring our work on private property is carried out to the reasonable
                                                satisfaction of the landowner and will always work to an agreeable solution
                                                where possible. We do have some legal powers under the Electronics Communication
                                                Code and the Telecommunications Infrastructure (Leasehold Property) Act 2021
                                                which means we can gain consent via the court to install apparatus on your land.
                                                However, we don’t want to use these unless we absolutely have to, and we will
                                                make best endeavours to explore all alternative options available to us.
                                            </Text>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="5" key="accordion-5">
                                        <Accordion.Header>What is the process for approval to build?</Accordion.Header>
                                        <Accordion.Body>
                                            <Text fontSize={2} lineHeight={1.75}>
                                                We start by sending the wayleave and the planning pack to the landlord for approval.
                                                Then if a pre-start site meeting is required, the Netomnia project manager and delivery
                                                manager will walk the site with the landlord and any relevant parties including residents' associations.
                                                This meeting aims to discuss any concerns with the current plan and make any necessary changes.
                                            </Text>
                                            <Text fontSize={2} lineHeight={1.75}>
                                                If the landlord is happy to move forward, we would then proceed with the network
                                                installation, after which we would offer a post-work meeting with the same parties.
                                            </Text>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="6" key="accordion-6">
                                        <Accordion.Header>Is your network an 'open' network?</Accordion.Header>
                                        <Accordion.Body>
                                            <Text fontSize={2} lineHeight={1.75}>
                                                Yes, we have built our network to be open to any ISP wishing to provide a service via our infrastructure.
                                            </Text>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </StyledAccordion>
                            </Col>
                        </Row>
                    </Container>
                </Section>

                <GradientSection>
                    <Container>
                        <Row>
                            <AbsoluteImage src={bottomMotive} />
                            <Col lg="6">
                                <Title className="centered">Contact Us</Title>
                            </Col>
                            <Col lg="6">
                                <Text className="w-80">
                                    If you want to get in touch with us
                                    with any enquiry, please email <a href="mailto:wayleaves@netomnia.com">wayleaves@netomnia.com</a> and our
                                    expert team will be on hand to help
                                    deal with your questions.
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </GradientSection>

            </PageWrapper>
        </>
    )
}

export default Wayleaves;
