import React, { Component } from "react";
import { Button, Text } from "./../../components/Core";
import { Row, Col } from "react-bootstrap";
import baby from "../../assets/image/svg/baby.svg";
import Image from "gatsby-image";

export default class TeamMembers extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            teamMembers: props.teamMembers
        };

        const teamMembers = this.state.teamMembers;

        this.state.teamMembers.forEach((e, i) => {
            teamMembers[i]['detailsVisible'] = false;
        });

        this.setState({ teamMembers: teamMembers });
    }

    toggleSection(section) {
        const teamMembers = this.state.teamMembers;

        teamMembers[section]['detailsVisible'] = !teamMembers[section]['detailsVisible'];

        this.setState({ teamMembers: teamMembers });    
    }

    render() {
        return <>
            <Row className="justify-content-center text-center">
                <Col lg="12">
                    <Row className="justify-content-center">
                        {
                            this.state.teamMembers.map((member, index) => {
                                return <div className="team-members-wrapper">
                                    <div className={member.detailsVisible ? 'team-member-excerpt details-visible' : 'team-member-excerpt'}>
                                        {
                                            member.photo && <Col lg="12" className="image-margin">
                                                <img alt="" src={member.photo.childImageSharp?.original.src}  className="img-fluid"/>
                                            </Col>
                                        }
                                        <Col lg="12" className="basic-data-info">
                                            <Row>
                                                <div>{member.name}<br/>{member.position}</div>
                                                {
                                                    member.linkedin && member.id !== "Team-members_17" && <div className="team-ld">
                                                        <a href={member.linkedin} target="_blank" rel="noreferrer">
                                                            <i className="icon icon-logo-linkedin pt-1"></i>
                                                        </a>
                                                    </div>
                                                }
                                                {
                                                    member.id == "Team-members_17" && <div className="team-ld">
                                                        <a href="https://cat-bounce.com/" className="baby-svg" target="_blank" rel="noreferrer">
                                                            <img alt="" width="28" src={baby}/>
                                                        </a>
                                                    </div>
                                                }
                                            </Row>
                                        </Col>
                                        {/*<Button className="read-more-btn" onClick={() => this.toggleSection(index)}>Read more</Button>*/}
                                    </div>
                                    <div className={member.detailsVisible ? 'team-member-details details-visible' : 'team-member-details'}>
                                        {member.details && <Text dangerouslySetInnerHTML={{ __html: member.details.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></Text>}
                                        <Button className="read-more-btn return-button" onClick={() => this.toggleSection(index)}>Return</Button>
                                    </div>
                                </div>
                            })
                        }
                    </Row>
                </Col>
            </Row>
        </>
     }
}